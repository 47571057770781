export default `
### Livsfas är en modell som beskriver hushållets livsfas.

* Ungdom. Hushåll där samtliga individer är 16-24 år och sannolikt inte har barn.
* Singel. Enpersonshushåll utan barn (25-64 år)
* DINK (Double income no kids) Par utan barn, två vuxna (minst en person är 25-49 år).
* Småbarnsfamilj. Familj som sannolikt har ett eller flera barn som är 0-6 år
* Familj med ungdom hemma. Familj som har en eller flera ungdomar (18-24 år) hemmaboende och sannolikt inga barn under 17 år
* Väletablerad familj. Familj som sannolikt har ett eller flera barn som är 7-17 år och inga barn under 6 år.
* Medelålders par. 50-64 år ingen person i hushållet är över 64 år. Familj som inte har några hemmaboende barn eller ungdom 0-24 år.    
* Seniorer Hushåll med personer som är 65 år eller äldre
`